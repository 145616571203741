<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
// import { required } from "vuelidate/lib/validators";
import { paymentMethods } from "@/state/helpers";
import {
  FETCH_ALL_PAYMENT_CHANNEL,
  FETCH_MERCHANTS_LIST
} from "@/state/actions.type";
import FilterBtn from '../../../../components/filter-btn.vue';
import Multiselect from "vue-multiselect";
import Common from "../../../../helpers/Common";

export default {
  components: {
    Layout,
    PageHeader,
    FilterBtn,
    Multiselect
},

  data() {
    return {
      showFilterSection: false,
      countryForFilter: [],
      isFilterApplied: true,
      merchant: null,
      countries: []
    };
  },

  computed: {
    paymentChannelCredsList() {
      return this.$store.state.paymentChannels.channels;
    },

    userCountry() {
      return this.$store.getters["auth/getCurrentUserCountry"];
    },

    chaiPayKey() {
      return this.$store.getters["auth/iamportKey"];
    },

    items() {
      return [
        {
          text: this.$t("menuitems.integration.list.payconfig"),
          active: true
        },
        {
          text: this.$t("menuitems.integration.list.pay-channels"),
          active: true,
        },
      ];
    },

    title() {
      return this.$t("menuitems.integration.list.payout-channels");
      // return this.$t("menuitems.integration.list.pay-channelstwo");
    },

    userRoles() {
      return this.$store.getters["auth/getUserRolesList"];
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  created() {
    this.countryForFilter = [ this.userCountry, 'GLOBAL' ];
    this.countries = Common.countryList();

    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" },
      }).then((response) => {
        if(response.content.length > 0) {
          this.merchant = response.content[0];
          this.fetchPaymentChannels();
        }
      })
    } else {
      this.fetchPaymentChannels();
    }

  },

  destroyed() {
    // document.body.classList.remove("dynamic-body");
  },

  validations: {
    paymentChannelCreds: {},
  },

  methods: {
    ...paymentMethods,
    onCopyWebhookURL() {
      this.$notify({
        type: "success",
        text: `PortOne provided webhook for ${this.currentChannel} copied to clipboard.`,
        closeOnClick: true,
      });
    },
    openFilter(){
      this.showFilterSection = !this.showFilterSection
    },
    saveFilter(){
      this.fetchPaymentChannels();
      this.showFilterSection = false;
      if(this.countryForFilter.length > 0) {
        this.isFilterApplied = true;
      } else {
        this.isFilterApplied = false;
      }
    },
    clearFilter(){
      this.countryForFilter = []
      this.fetchPaymentChannels();
      this.showFilterSection = false;
      this.isFilterApplied = false;
    },

    merchantSelected() {
      this.fetchPaymentChannels();
    },

    fetchPaymentChannels() {
      let payload = {
        country: this.countryForFilter,
        paymentMethod: "",
        paymentType: "PAYOUT"
      };
      if(this.merchant && this.isMasterMerchant) {
        payload.merchant_uuids = this.merchant.uuid;
      }
      this.$store.dispatch(`paymentChannels/${FETCH_ALL_PAYMENT_CHANNEL}`, payload);
    },
    channelConfigLink(data) {
      if(this.merchant) {
        return `/settings/payout-channel/update-config?channelName=${data.key}&&mKey=${this.merchant.uuid}&&mEmail=${this.merchant.email_id}`
      }
      return `/settings/payout-channel/update-config?channelName=${data.key}`;
    },
    getRegionName(countryCode) {
      let regionNames = new Intl.DisplayNames(['en'], {type: 'region'});
      return regionNames.of(countryCode);
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row justify-content-end mr-2">
      <multiselect
        v-if="isMasterMerchant"
        v-model="merchant"
        :options="merchantListDataFromStore"
        @input="merchantSelected"
        placeholder="Search Merchants"
        track-by="email_id"
        label="email_id"
        class="sm-multiselect col-md-3"
        :searchable="true"
        :multiple="false"
        :taggable="true"
      ></multiselect>
    </div>
    <div class="">
      <div class="pt-0">

        <b-tabs nav-class="nav-tabs-custom">
          <!-- Payment Channel tab -->
          <b-tab
            :title="$t('views.payments.transactions.payment_payouts')"
            title-link-class="p-3"
          >
            <div class="col-md-4 d-flex">
              <div @click="openFilter()" class="my-3 h-50" style="margin-left: 4%;"><FilterBtn :isActive="isFilterApplied" /></div>
              <div v-if="showFilterSection" class="filter-section">
                <div class="my-3 h-50" style="margin-left: 4%;">
                  <div @click="openFilter()" class="mb-2"><FilterBtn :isActive="isFilterApplied" /></div>
                </div>
                <div class="top-section">
                  <div class="row">
                    <div class="filter_channel">
                      <div class="filter_heading">COUNTRY</div>
                        <div class="checkbox-group"> 
                          <input type="checkbox" id="filter_global" value="GLOBAL" v-model="countryForFilter">
                          <label for="filter_global">Global</label>
                        </div>
                        <div class="checkbox-group" v-for="country in countries" :key="country">  
                          <input type="checkbox" :id="`filter_${country}`" :value="country" v-model="countryForFilter">
                          <label :for="`filter_${country}`">{{ getRegionName(country) }}</label>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="bottom-section float-right">
                  <button @click="clearFilter()" class="mr-2 btn btn-light btn-sm">{{ $t("button.clear") }}</button>
                  <button @click="saveFilter()" class="btn btn-primary btn-sm">
                    {{ $t("button.apply") }}
                  </button>
                </div>
              </div>
            </div>
            <div class="row" v-if="(isMasterMerchant && merchant) || ! isMasterMerchant">
              <div
                class="col-xl-3 col-md-4 col-lg-4 col-sm-6 dynamic-card-parent"
                v-for="(data, index) in paymentChannelCredsList"
                :key="index"
              >
                <div
                  class="card border dynamic-card"
                >
                  <div class="card-body text-center card-body-custom">
                    <router-link
                      :to="channelConfigLink(data)"
                      class=""
                    >
                    <div class="">
                      <div class="avatar-background avatar-lg"></div>
                      <img
                        :src="`${data.logo}`"
                        alt
                        class="avatar-lg"
                        style="object-fit: contain;position: relative;"
                      />
                      <div class="media-body media-body-custom text-left">
                        <p class="text-muted mb-0 mt-2 d-inline-block">
                          {{ data.key }} {{ data.type }}
                        </p>
                        <img v-if="!data.is_enabled" src="@/assets/images/arrow.png" alt />
                        <img v-if="data.is_enabled" src="@/assets/images/tick.png" alt />
                      </div>
                    </div>

<!--                     <button
                      href="javascript:void(0);"
                      class="btn btn-primary btn-block"
                      v-if="!data.is_enabled"
                      @click="openPayInModal(data)"
                    >
                      {{ $t("button.setup") }}
                    </button>
                    <button
                      href="javascript:void(0);"
                      class="btn btn-success btn-block"
                      v-if="data.is_enabled"
                      @click="openPayInModal(data)"
                    >
                      {{ $t("button.edit") }}
                    </button> -->
                    <!-- <button href="javascript:void(0);" class="btn btn-block" :class="{ 'btn-primary': data.launched, 'btn-light': !data.launched}">Edit Setup</button> -->
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
input:focus:required:invalid {
  color: green;
}

::v-deep .nav-tabs-custom {
  margin-bottom: 24px;
  border-bottom: 1px solid #D9D9D9;
}
.card-body-custom{
  padding:10px;
  cursor: pointer;
}
.media-body-custom{
  border-top: 1px solid #DCE8FC;
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: absolute;
  right: 0px;
  top: 10px;
}
.avatar-background{
    background-image: url("../../../../assets/images/dots.png");
    background-size: 60px;
    background-position-x: 25%;
    background-position-y: center;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    left: 0px;
}
@media (min-width: 1550px) {
    .avatar-background{
      background-position-x: 30%;
  }
}
@media (max-width: 575px) {
    .avatar-background{
      background-position-x: 30%;
  }
}
.dynamic-card{
  width: 88%;
  margin: auto;
  margin-bottom: 24px;
  box-shadow: 0 5px 15px rgba(0,0,0,.1);
}
::v-deep .nav-tabs > li > a{
  color: #B3AFAF;
}
::v-deep .nav-tabs-custom .nav-item .nav-link.active{
  color:#252B3B;
}
::v-deep .nav-tabs-custom .nav-item .nav-link::after{
  background:#252B3B;
}
@media (max-width: 575px) {
  ::v-deep .page-title-box{
    display: block !important;
    padding-bottom: 10px;
  }
  ::v-deep .page-title-box h4{
    margin-bottom: 5px!important;
  }
}
.filter-section{
    box-shadow: 0px 3px 10px #0000001a;
    background: #fff;
    position: absolute;
    top: 0px;
    z-index: 1;
    width: 280px;
}
.top-section{
    border-bottom: 1px solid #F0F0F0;
    padding: 15px;
    padding-left: 35px;
}
.clear_filter{
  color: #BFBFBF;
  display: inline-block;
  margin-left: 15px;
  font-size: 13px;
  cursor: pointer;
}
.bottom-section{
    padding: 15px;
    padding-left: 35px;
}
.filter_heading{
    color: #BFBFBF;
    padding-bottom: 15px;
    font-size: 13px;
}
.checkbox-group{
  position: relative;
}
.checkbox-group input{
  display: none;
}
.checkbox-group  label {
  cursor: pointer;
  font-size: 13px;
}
.checkbox-group  label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #fff;
  border: 1px solid #DAD9D9;
  border-radius: 4px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
  bottom:1px;
}
.checkbox-group input:checked+label:before {
  background-color: #252B3A;
  border: 1px solid #252B3A;
}

.checkbox-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 5px;
  left: 6px;
  width: 5px;
  height: 9px;
  border: 1px solid #fff;
  border-width: 0px 2px 2px 0px;
  transform: rotate(45deg);
}
.filter_method{
    float: right;
    padding-right: 30px;
}
.filter_channel {
  height: 40vh;
  overflow-y: scroll;
}
</style>